@import url('../../../node_modules/antd/dist/antd.css');
.option-point {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    column-gap: 10px;
}

.card {
    height: auto;
}

.card-imgs {
    object-fit: cover;
    background-size: cover;
    padding: 10px;
    width: auto;
    height: 400px;
    padding-bottom: 20px;
}

.css-1s2u09g-control {
    height: 40px;
    width: 200px;
    background-color: transparent;
    min-width: 200px;
    max-width: 200px;
    width: 200px;
    color: white;
}

.css-1okebmr-indicatorSeparator {
    display: none;
}

.select__value-container {
    width: 200px;
}

.css-1s2u09g-control {
    background-color: transparent !important;
}

.css-14el2xx-placeholder {
    background-color: transparent;
    color: white;
    text-align: start;
    font-weight: bold;
}

.ant-select {
    height: 40px;
}

.ant-select:hover {
    border-color: white !important;
}

.ant-select-selection-placeholder {
    background-color: transparent;
    color: white;
    text-align: start;
    font-weight: bold;
}

.ant-select-selector {
    background-color: transparent !important;
    height: 40px !important;
    border-radius: 5px !important;
    display: flex;
    align-items: center;
}

.ant-select-selector:hover {
    border-color: white !important;
}

.ant-select-selection-item {
    color: white;
    text-align: start;
}

.ant-select-arrow {
    color: white;
}

.ant-select-dropdown {
    border: 1px solid white;
    color: white;
    background-color: #181818;
    border-radius: 5px;
}

.ant-select-item {
    color: white;
}

.ant-select-item:focus {
    background-color: #a9a6a6;
    ;
}

.ant-select-item:hover {
    background-color: #a9a6a6;
    ;
}

.ant-select-item-option-active {
    background-color: #a9a6a6 !important;
}

.youknowme {
    background: rgba(24, 24, 24, 0.5);
    border-radius: 12px;
    padding: 20px 20px 20px;
    max-width: 420px;
    color: whitesmoke;
    font-weight: bold;
}

.headertitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navigator {
    /* position: absolute;
    right: 7%;
    top: -22%; */
    height: 48px;
    width: 48px;
    line-height: 30px;
    font-size: 20px!important;
    background: rgba(0, 0, 0, 0.6)!important;
    color: white !important;
    border: 1px solid var(--body-txt) !important;
    border-radius: 4px !important;
}

.navigator-container {
    display: flex;
    column-gap: 10px;
}