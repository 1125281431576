.error-color {
    border-color: red !important;
}

.socialIcon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 10px;
    background-color: transparent;
}

.socialIcon:nth-child(odd) {
    /* border: 1px solid red; */
    margin-right: 10px;
}


/* .socialIcon:nth-child(even) {
    border: 1px solid aqua;
} */

.logo1 {
    width: 40px;
    height: 40px;
}