

/*======== lOGIN SIGNUP FORM STYLE START HERE ========*/

.login-signup-form .form-section {
    background-color: #222;  
    border-radius: 20px; 
    padding: 25px 90px;
}

.login-signup-form .form-section.connect-wallet {
  padding: 60px 90px;
}

.login-signup-form .form-section h1 {
font-weight: 400;
font-size: 36px;
line-height: 44px;
letter-spacing: 0.02em;
text-transform: uppercase;
color: var(--body-txt);
margin-bottom: 25px;
font-family: 'Face Off M54';
}

.form-section .form-group {
  margin-bottom: 20px;
}

.form-section .form-group label, 
.form-section .form-group .form-check label {
  color: var(--body-txt);
  font-size: 14px;
  line-height: 20px;
}

.form-section .form-group .form-check-input {
background-color: #181818;
border: 1px solid #737373;
}

.form-section .form-group .form-check-input[type=checkbox] {
 border-radius: 2px;
}

.form-section .form-group .form-check-input:checked {
background-color: var(--primary);
border-color: var(--primary);
}

.form-section .form-group .form-control {
background: #181818;
border: 1px solid #737373;
box-sizing: border-box;
border-radius: 4px;
color: #fff;
font-size: 14px;
}

.form-section .form-group a {
  color: var(--primary);
}

.form-section .form-group p {
  color: var(--body-txt);
  margin-bottom: 0px;
  font-size: 14px;
}

.form-section .form-group .or-sec {
    position: relative;
    color: #737373;
    font-size: 14px;
    text-align: center;
    z-index: 99;
}

.form-section .form-group .or-sec span {
  padding: 5px 10px;
  background: #222222;
}

.form-section .form-group .or-sec::after {
  content:'';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #737373;
  z-index: -1;
}

.form-section .form-group .btn {
  font-weight: 700;
  text-transform: lowercase;
}

.form-section.connect-wallet .form-group .btn img {
margin-right: 10px;
width: 25px;
}

.form-section .form-group span.forg-link {
float: right;
font-size: 14px;
}

@media (max-width: 992px) {

    .login-signup-form {
    padding-top: 90px;
    }

    .login-signup-form .form-section {
    padding: 20px 40px;
    }

    .login-signup-form .form-section.connect-wallet {
    padding: 40px 40px;
    }

    .login-signup-form .form-section h1 {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 15px;
    }
}


/*======== lOGIN SIGNUP FORM STYLE END ========*/