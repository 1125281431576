


/*======== FOOTER STYLE START HERE ========*/

.footer .overlay {
    background-color: #222;
    color: var(--body-txt);
  }
  
  .footer .footer-logo {
    max-width: 180px;
    margin-bottom: 30px;
  }
  
  .footer h1 {
    color:var(--body-txt);
    text-transform: uppercase;
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 32px;
    font-family: 'Face Off M54';
    font-weight: 400;
    letter-spacing: 0.02em;
    font-style: normal;
  }
  
  .footer p.pra-top {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #999;
  }
  
  .footer .footer-nav li.nav-item {
    list-style-type: none;
    display: block;
  }
  
  .footer .footer-nav li.nav-item a {
    text-transform: uppercase;
    font-size: 14px;
    margin: 4px 0px;
    line-height: 24px;
    color: var(--body-txt);
    padding: 0.1rem 1rem 0.1rem 0rem;
  }

  .footer .footer-nav li.nav-item:hover a {
    color: #777;
  }
  
  .footer p.pra-bottom {
    line-height: 16px;
    font-size: 12px;
  }
  
  @media (min-width: 1500px){
    .footer .footer-nav li.nav-item a {
        font-size: 16px;
    }
  }

  @media (max-width: 992px) {
    .footer h1 {
      font-size: 20px;
    }

    .footer .order-last {
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    .footer h1 {
      font-size: 18px;
      margin-bottom: 15px;
    }
  }

  @media (max-width: 767px) {
    ul.footer-nav {
      margin-bottom: 30px;
    }
  }



  /*======== FOOTER STYLE END HERE ========*/