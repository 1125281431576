

/*======== OWL CAROUSEL COLLECTION CARD ========*/

.owl-card-slider .card-item {
    background: rgb(34 34 34 / 40%);
    border-radius: 12px;
    border-radius: 12px;
    padding: 20px;
    height: 100%;
    margin: 10px 15px;
    border: 0px;
  }
  
  .owl-card-slider .card-item .card-imgs img {
      max-width: auto;
      height: 370px;
      width: auto;
      margin: 10px auto 20px auto;
  }

  .owl-card-slider .card-item .card-imgs video {
    object-fit: cover;
    background-size: cover;
    padding: 10px;
    width: auto;
    height: 400px;
  }
  
  .owl-card-slider .card-item .card-content {
    font-size: 18px;
    color: var(--body-txt);
    margin-top: 5px;
    line-height: 24px;
  }
  
  .owl-card-slider .card-item .card-content h6 {
    font-weight: 700;
    margin-bottom: 0px;
    font-size: 18px;
  }
  
  .owl-card-slider .card-item .card-content p {
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  .owl-card-slider .card-item .card-content a {
    color: var(--primary);
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    text-transform: uppercase;
  }
  
  .owl-card-slider .owl-prev {
    position: absolute;
    left: 3%;
    top: 40%;
    height: 48px;
    width: 48px;
    line-height: 30px;
    font-size: 20px!important;
    background: rgba(0, 0, 0, 0.6)!important;
    color: var(--body-txt) !important;
    border: 1px solid var(--body-txt) !important;
    border-radius: 4px !important;
    display: none;
  }
  
  .owl-card-slider .owl-next {
    position: absolute;
    right: 3%;
    top: 40%;
    height: 48px;
    width: 48px;
    line-height: 30px;
    font-size: 20px!important;
    background: rgba(0, 0, 0, 0.6)!important;
    color: var(--body-txt) !important;
    border: 1px solid var(--body-txt) !important;
    border-radius: 4px !important;
  }
  
  .owl-card-slider .owl-prev i, .owl-card-slider .owl-next i {
    font-size: 25px;
    position: relative;
    top: 2px;
  }

  .marketplace-owl-slider .card-item {
    background: rgba(24, 24, 24, 0.5);
    border-radius: 12px;
    padding: 20px 20px 20px;
    height: 100%;
    margin: 10px 15px; 
    border: 0;
  }

  .marketplace-owl-slider .card-item .card-imgs img {
    max-width: 250px;
    height: auto;
    width: 100%;
    margin: 0px auto 70px auto;
  }

  .marketplace-owl-slider .card-item .card-content {
    font-size: 14px;
    color: var(--body-txt);
    margin-top: 5px;
    line-height: 24px;
    position: absolute;
    bottom: 9px;
    left: 0;
    width: 100%;
    padding: 10px 20px;
  }

  .marketplace-owl-slider .card-item .card-content p {
    margin-bottom: 0px;
  }

  .marketplace-owl-slider .card-item .card-content span.title {
    color: var(--text-dark);
  }

  .marketplace-owl-slider .card-item .card-content span.price {
    font-size: 16px;
    font-weight: 700;
  } 

  .marketplace-owl-slider .card-item .card-content span.price img {
    width: 20px;
    display: inline;
  }

  .marketplace-owl-slider .owl-prev {
    position: absolute;
    right: 7%;
    top: -22%;
    height: 48px;
    width: 48px;
    line-height: 30px;
    font-size: 20px!important;
    background: rgba(0, 0, 0, 0.6)!important;
    color: var(--body-txt) !important;
    border: 1px solid var(--body-txt) !important;
    border-radius: 4px !important;
  }
  
  .marketplace-owl-slider .owl-next {
    position: absolute;
    right: 2%;
    top: -22%;
    height: 48px;
    width: 48px;
    line-height: 30px;
    font-size: 20px!important;
    background: rgba(0, 0, 0, 0.6)!important;
    color: var(--body-txt) !important;
    border: 1px solid var(--body-txt) !important;
    border-radius: 4px !important;
  }
  
  .marketplace-owl-slider .owl-prev i,
  .marketplace-owl-slider .owl-next i {
    font-size: 25px;
    position: relative;
    top: 2px;
  }

  @media (max-width: 992px) {
    .owl-card-slider .card-item .card-content h6, 
    .owl-card-slider .card-item .card-content p 
     {
      font-size: 14px;
     }

     .marketplace-owl-slider .owl-prev {
       top: -20%;
       right: 10%;
     }

     .marketplace-owl-slider .owl-next {
       top: -20%;
     }

  }

  @media (max-width: 992px) and (min-width: 768px) {
    .marketplace-owl-slider .card-item .card-imgs img {
      margin: 0px auto 90px auto; 
    }

    .marketplace-owl-slider .card-item .card-content {
      font-size: 10px;
      line-height: 17px;
      padding: 10px 10px;
    }

    .marketplace-owl-slider .card-item .row {
      margin-right: 0px !important;
      margin-left: 0 !important;
    }

    .marketplace-owl-slider .card-item .row .col-md-6 {
      padding-left: 0 !important;
      padding-right: 0.25rem !important;
    }

    .marketplace-owl-slider .card-item .card-content span.price {
      font-size: 10px;
    }

    .marketplace-owl-slider .card-item .card-content span.price img {
      width: 13px;
    }
  }

  @media (max-width: 767px) {
    .marketplace-owl-slider .card-item .card-imgs img {
      margin: 0px auto 20px auto; 
    }

    .marketplace-owl-slider .card-item .card-content {
      position: relative;
    }

    .marketplace-owl-slider .card-item .card-content .col-md-6.text-end {
      text-align: left !important;
    }

    .marketplace-owl-slider .owl-prev {
      right: 10%;
      top: -13%;
      height: 35px;
      width: 35px;
    }

    .marketplace-owl-slider .owl-next {
      right: 2%;
      top: -13%;
      height: 35px;
      width: 35px;
    }
  }


  /*======== OWL CAROUSEL COLLECTION CARD END ========*/


  /*======== OWL CAROUSEL EX SPORTS TV SECTION ========*/

  .ex-tv-slider .ex-item {
    max-width: 900px;
    margin: auto;
  }
  
  .ex-tv-slider .ex-item img {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .ex-tv-slider .ex-item .ex-tv-content h1 {
    font-family: 'Face Off M54';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 0.02em;
    text-transform: lowercase;
    color: var(--body-txt);
    margin-bottom: 20px;
  }
  
  .ex-tv-slider .ex-item .ex-tv-content p {
    font-size: 18px;
    line-height: 20px;
    color: #777;
    font-weight: 400;
  }
  
  .ex-tv-slider .ex-item .ex-tv-content p img {
    width: 15px;
    display: inline-block;
    margin-bottom: 0;
  }
  
  .ex-tv-slider .owl-prev {
    position: absolute;
    left: 23%;
    top: 25%;
    height: 48px;
    width: 48px;
    line-height: 30px;
    font-size: 20px!important;
    background: rgba(0, 0, 0, 0.6)!important;
    color: var(--body-txt) !important;
    border: 1px solid var(--body-txt) !important;
    border-radius: 4px !important;
  }
  
  .ex-tv-slider .owl-next {
    position: absolute;
    right: 23%;
    top: 25%;
    height: 48px;
    width: 48px;
    line-height: 30px;
    font-size: 20px!important;
    background: rgba(0, 0, 0, 0.6)!important;
    color: var(--body-txt) !important;
    border: 1px solid var(--body-txt) !important;
    border-radius: 4px !important;
  }

  /*======== OWL CAROUSEL EX SPORTS TV SECTION END ========*/