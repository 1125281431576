

/*====== TOP NAVIGATION STYLE SHEET ====== */


.header-section .overlay  {
    background-color: var(--header-bg);
}

.header-section .overlay .navbar .navbar-brand {
    max-width: 180px;
    padding: 0;
}

.header-section .overlay .navbar .navbar-nav li {
  padding-top: 35px;
  padding-bottom: 35px;
  position: relative;
  margin: 0px 5px;
}

.header-section .overlay .navbar .navbar-nav li:hover, 
.header-section .overlay .navbar .navbar-nav li.active {
    background: var(--nav-hover);
}

.header-section .overlay .navbar .navbar-nav li::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 8px;
  background-color: var(--primary);
  transition: all 0.3s;
}

.header-section .overlay .navbar .navbar-nav li:hover::after, 
.header-section .overlay .navbar .navbar-nav li.active::after {
  width: 100%;
}

.header-section .overlay .navbar .navbar-nav li a {
  color: var(--body-txt);
  font-weight: 700;
  font-size: 16px;
  padding: 0px 1rem;
}

.header-section .overlay .navbar .navbar-nav li.nav-dropdown {
  position: relative;
}

.header-section .overlay .navbar .navbar-nav li.nav-dropdown a.nav-dropdown-link {
  position: relative;
  padding-right: 25px;
}

.header-section .overlay .navbar .navbar-nav li.nav-dropdown a.nav-dropdown-link:after {
  content: '';
  position: absolute;
  right: 3px;
  top: 45%;
  width: 12px;
  height: 8px;
  background-image: url('../../images/icons/dropdown-icn.svg');
  background-position: center center;
  background-repeat: no-repeat;
  transition: all 0.3s;
}

.header-section .overlay .navbar .navbar-nav li.nav-dropdown:hover a.nav-dropdown-link::after {
  transform: rotate(180deg);
}

.header-section .overlay .navbar .navbar-nav li.nav-dropdown a img {
  margin-left: 10px;
}

.header-section .overlay .navbar .navbar-nav li.nav-dropdown ul.nav-column {
  position: absolute;
  width: auto;
  min-width: 100%;
  height: auto;
  background-color: var(--secondary);
  top: 100%;
  /* visibility: hidden; */
  /* transition: all 0.3s; */
  z-index: 99;
  clip: rect(1px, 1px, 1px, 1px);
  opacity: 0.1;
  transition: transform 0.1s cubic-bezier(0.2, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.2, 0, 0.2, 1);
  transform: translateY(-10px) scaleY(0.5);
  transform-origin: top;
}

.header-section .overlay .navbar .navbar-nav li.nav-dropdown ul.nav-column li {
  display: block;
  list-style-type: none;
  border-bottom: 1px solid var(--border-dark);
  padding: 10px 5px;
  margin: 0;
}

.header-section .overlay .navbar .navbar-nav li.nav-dropdown ul.nav-column li::after {
  height: 3px;
}

.header-section .overlay .navbar .navbar-nav li.nav-dropdown:hover ul.nav-column {
  clip: auto;
  opacity: 1;
  transform: translateY(0) scaleY(1);
}

.header-section .overlay .navbar .btn-sec-navbar .btn {
  margin: 0px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-section .overlay .navbar .btn-sec-navbar {
  display: flex;
}

.header-section .overlay .navbar .btn-sec-navbar .f-group {
  width: 150px;
}


.btn-sec-navbar .f-group .f-dropdown > span {
  background: #181818;
  border: 1px solid #737373;
  box-sizing: border-box;
  border-radius: 4px;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
}

.header-section .overlay .navbar .btn-sec-navbar .f-group .f-dropdown ul li a img, 
.navbar .btn-sec-navbar .f-group .f-dropdown > span img {
    width: 15px;
    margin-right: 5px;
}

.btn-sec-navbar .f-group .f-dropdown.open > span:before, 
.btn-sec-navbar .f-group .f-dropdown.open > span:after, 
.btn-sec-navbar .f-group .f-dropdown > span:before, 
.btn-sec-navbar .f-group .f-dropdown > span:after {
  background: #fff;
}

.header-section .overlay .navbar .btn-sec-navbar .f-group .f-dropdown ul li a {
  color: #181818 !important;
}

.header-section .overlay .navbar .navbar-toggler i {
  color: #fff;
}

@media (max-width: 1200px) and (min-width: 992px) {

  .header-section .overlay .navbar .navbar-brand img {
      width: 100px;
  }

  .header-section .overlay .navbar .navbar-nav li a {
      font-size: 14px;
      padding: 0px 0.5rem;
  }

  .header-section .overlay .navbar .btn-sec-navbar .btn {
    font-size: 14px !important;
  }
}

@media (max-width: 992px) {

  .header-section  {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 9999;
  }

  .header-section .overlay {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .header-section .overlay .navbar .btn-sec-navbar.btn-sec-desktop {
    display: none;
  }

  .header-section .overlay .navbar .navbar-nav {
    margin-top: 20px;
    height: 90vh;
  }

  .header-section .overlay .navbar .navbar-nav li {
      padding-top: 15px;
      padding-bottom: 15px;
  }

  .header-section .overlay .navbar .navbar-nav li a {
    font-size: 14px;
  }

  .header-section .overlay .navbar .navbar-nav li::after {
    height: 4px;
  }

  .header-section .overlay .navbar .navbar-nav li.nav-dropdown a.nav-dropdown-link:after {
    right: 15px;
  }

  .header-section .overlay .navbar .navbar-nav li.nav-dropdown ul.nav-column {
    position: static;
    height: 0;
    opacity: 0;
  }

  .header-section .overlay .navbar .navbar-nav li.nav-dropdown:hover ul.nav-column {
    height: auto;
  }

  .header-section .overlay .navbar .btn-sec-navbar .f-group {
      width: 100%;
      max-width: 100%;
  }
}


/*======== TOP NAVIGATION STYLE END========*/