.main-banner .overlay {
    position: relative;
}

.main-banner .overlay .carousel-inner .carousel-item img {
    display: block;
    overflow: hidden;
    width: 100%;
}

.main-banner .overlay .carousel-inner .carousel-item .carousel-caption {
    text-align: left;
    top: 15%;
}

.main-banner .overlay .carousel-inner .carousel-item .carousel-caption .caption-title {
    color: var(--primary);
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 15px;
}

.main-banner .overlay .carousel-inner .carousel-item .carousel-caption h1 {
    color: var(--body-txt);
    text-transform: uppercase;
    margin-bottom: 30px;
    font-size: 48px;
    line-height: 60px;
    font-family: 'Face Off M54';
    font-weight: 400;
    letter-spacing: 0.02em;
    font-style: normal;
}

.main-banner .overlay .carousel-inner .carousel-item .carousel-caption .btn {
    max-width: 400px;
    display: block;
}

.carousel-btn {
    background: rgba(0, 0, 0, 0.6);
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    width: 48px;
    height: 48px;
}

.main-banner .overlay .carousel .carousel-btn {
    top: 45%;
}

.main-banner .overlay .carousel .carousel-btn.carousel-control-prev {
    left: 5%;
}

.main-banner .overlay .carousel .carousel-btn.carousel-control-next {
    right: 5%;
}


/*==== MAIN BANNER CAROUSEL SMALL SCREEN ====*/

@media (max-width: 992px) {
    .main-banner {
        padding-top: 90px;
    }
    .main-banner .overlay .carousel-inner .carousel-item .carousel-caption .caption-title {
        margin-bottom: 0px;
    }
    .main-banner .overlay .carousel-inner .carousel-item img {
        object-fit: cover;
        height: 320px;
    }
    .main-banner .overlay .carousel-inner .carousel-item .carousel-caption h1 {
        margin-bottom: 15px;
        font-size: 30px;
        line-height: 36px;
    }
    .main-banner .overlay .carousel-inner .carousel-item .carousel-caption .btn {
        max-width: 200px;
        font-size: 14px;
        padding: 10px;
        line-height: 22px;
    }
    .main-banner .overlay .carousel .carousel-btn {
        top: 33%;
    }
    .main-banner .overlay .carousel .carousel-btn {
        top: 35%;
    }
    .carousel-btn {
        width: 40px;
        height: 40px;
    }
    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        width: 1.5rem;
        height: 1.5rem;
    }
}

.collection-explore .overlay,
.bg-main .overlay {
    background-image: url('../../images/bg-1.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 100vh; */
}

.collection-explore .overlay {
    position: relative;
}

.collection-explore h1 {
    color: var(--body-txt);
    text-transform: uppercase;
    margin-bottom: 30px;
    font-size: 48px;
    line-height: 60px;
    font-family: 'Face Off M54';
    font-weight: 400;
    letter-spacing: 0.02em;
    font-style: normal;
}

.collection-explore .sort-col .n-select {
    width: 180px;
    display: inline-block;
    background-color: transparent;
    color: #fff;
    float: none;
}

.collection-explore .nice-select .list {
    width: 100%;
    background: #181818;
    border: 1px solid #737373;
}

.collection-explore .nice-select .option:first-child {
    display: none;
}

.collection-explore .nice-select .option:hover,
.collection-explore .nice-select .option.focus,
.collection-explore .nice-select .option.selected.focus {
    background-color: #a9a6a6;
}

.collection-explore .nice-select:after {
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    height: 8px;
    width: 8px;
    margin-top: -5px;
    right: 15px;
}

.collection-card-row .card {
    background: rgba(24, 24, 24, 0.5);
    border-radius: 12px;
    padding: 20px 20px 20px;
    height: 100%;
}

.collection-card-row .card .card-img {
    max-width: 250px;
    height: auto;
    margin: 0px auto 70px auto;
}

.collection-card-row .card .card-caption-text {
    font-size: 14px;
    color: var(--body-txt);
    margin-top: 5px;
    line-height: 24px;
    position: absolute;
    bottom: 9px;
    left: 0;
    width: 100%;
    padding: 10px 20px;
}

.collection-card-row .card .card-caption-text p {
    margin-bottom: 0px;
}

.collection-card-row .card .card-caption-text span.title {
    color: var(--text-dark);
}

.collection-card-row .card .card-caption-text span.price {
    font-size: 16px;
    font-weight: 700;
}

.collection-card-row .card .card-caption-text span.price img {
    width: 20px;
}

.collection-explore a.view-market {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    text-transform: uppercase;
    color: var(--body-txt);
}

.collection-explore .btn-light-outline {
    background: rgba(14, 4, 4, 0.6) !important;
    width: 100%;
    font-size: 16px;
    font-weight: 700;
}

@media (max-width: 992px) and (min-width: 768px) {
    .collection-card-row .card {
        padding: 20px 10px;
    }
    .collection-card-row .card .card-caption-text {
        font-size: 10px;
        line-height: 17px;
        padding: 10px 10px;
    }
    .collection-card-row .card .row {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .collection-card-row .card .row .col-md-6 {
        padding-left: 0 !important;
        padding-right: 0.25rem !important;
    }
    .collection-card-row .card .card-caption-text span.price {
        font-size: 10px;
    }
    .collection-card-row .card .card-caption-text span.price img {
        width: 13px;
    }
}

@media (max-width: 767px) {
    .collection-card-row .card {
        padding: 20px 10px;
    }
    .collection-card-row .card .card-img {
        margin: 0px auto 20px auto;
    }
    .collection-card-row .card .card-caption-text .col-md-6.text-end {
        text-align: left !important;
    }
    .collection-card-row .card .card-caption-text {
        position: relative;
    }
}

.ex-tv .overlay {
    background-color: #181818;
}

.ex-tv h1 {
    color: var(--body-txt);
    text-transform: uppercase;
    margin-bottom: 30px;
    font-size: 48px;
    line-height: 60px;
    font-family: 'Face Off M54';
    font-weight: 400;
    letter-spacing: 0.02em;
    font-style: normal;
}

.ex-tv .btn-light-outline {
    padding: 8px 80px;
}

.ex-tv-smallscreen .ex-item {
    max-width: auto;
    margin: auto;
}

.ex-tv-smallscreen .ex-item .ex-tv-img img {
    width: 100%;
}

.ex-tv-smallscreen .ex-item .ex-tv-content p {
    font-size: 14px;
    line-height: 20px;
    color: #777;
    font-weight: 400;
}

@media (max-width: 992px) {
    .collection-explore h1 {
        margin-bottom: 0px;
        font-size: 40px;
    }
    .collection-explore .sort-col .n-select {
        width: 150px;
    }
    .ex-tv.ex-tv-desktop {
        display: none;
    }
    .ex-tv h1 {
        font-size: 20px;
        line-height: 25px;
        margin-top: 20px;
    }
    /* .collection-card-row .card {
      padding: 20px 10px;
    }

    .collection-card-row .card .card-caption-text {
      padding: 10px 8px;
    }

    .collection-card-row .card .card-caption-text p {
      font-size: 10px;
      line-height: 18px;
    }

    .collection-card-row .card .card-caption-text span.price {
      font-size: 10px;
    } */
}

.collection-explore .overlay .filter-title {
    display: flex;
    align-items: flex-start;
}

.collection-explore .overlay .filter-title h1 {
    font-size: 40px;
    line-height: 38px;
}

.collection-explore .overlay .open-filter {
    width: 50px;
    height: 48px;
    line-height: 48px;
    background-color: var(--primary);
    color: #fff;
    border-radius: 4px;
    font-size: 30px;
    text-align: center;
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;
}

.collection-explore .overlay .filter-side {
    width: 480px;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: #181818;
    padding: 50px 30px;
    left: -100%;
    transition: 0.3s ease-in-out;
}

.collection-explore .overlay .filter-side.active {
    left: 0;
}