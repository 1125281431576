
/*======== MAIN DEFAULT STYLE START HERE ========*/

@import "face-off-font.css";

:root {
	--body-font: 'Open Sans', sans-serif;
	--body-hd-font: 'FaceOffM54', sans-serif;
	--body-color: #222222;
	--main-bg: linear-gradient(180deg, #B71F23 0%, #180F52 100%);
  --nav-hover: linear-gradient(180deg, #232323 0%, rgba(97, 97, 97, 0) 100%);
	--body-txt: #fff;
	--primary: #B71F23;
	--secondary: #181818;
	--header-bg: #000000;
  --border-dark: #737373;
  --text-dark: #737373;
	
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--body-font);
  background-color: var(--body-color);
  font-size: 18px;
  line-height: 30px;
  padding: 0;
  margin: 0;
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
}

section,
footer {
  overflow-x: hidden;
}

.btnm-primary {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color: var(--body-txt);
  font-size: 16px;
  line-height: 24px;
  border-radius: 4px;
  padding: 12px;
  font-weight: 700;
}

.btnm-primary img {
  margin-right: 10px;
}

.btnm-primary:hover {
  color: var(--body-txt);
  opacity: 0.9;
}

.btn-light-outline {
  border: 1px solid #FFFFFF !important;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 12px;
  color: var(--body-txt) !important;
  background-color: transparent !important;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
}

.btn-light-outline:hover {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

a,
a:focus,
a:hover, 
.btn:focus, 
.btn:hover  {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

input:focus,
textarea:focus, 
.form-control:focus {
  outline: none;
  box-shadow: none;
  box-shadow: none;
}

button {
  border: none;
  cursor: pointer;
}

.pr-10 {
  padding-right: 10%;
}

.form-check .form-check-input {
  margin-left: -32px !important;
  width: 24px !important;
  height: 24px !important;
}

.form-check-input:checked {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.form-group label {
  font-size: 14px;
}

.form-group .form-control {
  background: #181818;
  border: 1px solid #737373;
  box-sizing: border-box;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
}

@media (min-width: 1450px) {
  .pr-10 {
    padding-right: 25%;
  }
}


@media (max-width: 992px) {
  .pt-sm-90 {
    padding-top: 90px;
  }

  .hide-sm {
    display: none;
  }
}

@media (max-width: 767px) {
  .hide-xsm {
    display: none;
  }
}



/*======== MAIN DEFAULT STYLE END HERE ========*/