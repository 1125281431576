@import url('./navbar.css');
.coin-select {
    width: 140px !important;
    height: 49px !important;
    /* background: transparent; */
    background-color: black !important;
    color: white;
}

.coin-select .list {
    width: 140px !important;
    height: auto;
    overflow-y: auto !important;
    background-color: black !important;
    scrollbar-width: 10px;
}

.coin-select .list li:hover {
    color: black !important;
}

.coin-select .list li .selected {
    color: black !important;
}

.coin-select .list li .focus {
    color: black !important;
}

::-webkit-scrollbar {
    width: 7px;
}

::-webkit-scrollbar-thumb {
    background: red;
}

.login-btn {
    padding: 10px 34px !important;
    height: 40px !important;
}

.header-login-image {
    height: 27px;
}

.login-class {
    color: white;
}

.myProfile {
    /* right: 8%;
    top: 10px;
    position: absolute; */
}

.myProfile .share-btn {
    /* width: 48px;
    height: 48px; */
    height: 51px;
    background: rgba(0, 0, 0, 0.6);
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    line-height: 45px;
    color: #fff;
    padding: 0 20px !important;
    /* font-size: 30px; */
}

.myProfile .dropdown-menu {
    background: #181818;
    border: 1px solid #999999;
    box-shadow: 0px 8px 16px rgb(0 0 0 / 25%);
    border-radius: 4px;
}

.myProfile .dropdown-menu li a {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
}