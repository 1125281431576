/*====== TOP NAVIGATION STYLE SHEET ====== */

.header-section .overlay {
    background-color: var(--header-bg);
    /* padding-top: 10px;
    padding-bottom: 10px; */
    max-height: 100px;
    display: flex;
    align-items: center;
}

.header-section .overlay .navbar .navbar-brand {
    max-width: 180px;
    padding: 0;
}

.header-section .overlay .navbar .navbar-brand img {
    width: 150px;
}

.header-section .overlay .navbar .navbar-nav li {
    padding-top: 25px;
    padding-bottom: 25px;
    position: relative;
    margin: 0px 5px;
}

.header-section .overlay .navbar .navbar-nav li:hover,
.header-section .overlay .navbar .navbar-nav li.active {
    background: var(--nav-hover);
}

.header-section .overlay .navbar .navbar-nav li::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 5px;
    background-color: var(--primary);
    transition: all 0.3s;
}

.header-section .overlay .navbar .navbar-nav li:hover::after,
.header-section .overlay .navbar .navbar-nav li.active::after {
    width: 100%;
}

.header-section .overlay .navbar .navbar-nav li a {
    color: var(--body-txt);
    font-weight: 700;
    font-size: 16px;
    padding: 0px 1rem;
}

.header-section .overlay .navbar .navbar-nav li.nav-dropdown {
    position: relative;
}

.header-section .overlay .navbar .navbar-nav li.nav-dropdown a.nav-dropdown-link {
    position: relative;
    padding-right: 25px;
}

.header-section .overlay .navbar .navbar-nav li.nav-dropdown a.nav-dropdown-link:after {
    content: '';
    position: absolute;
    right: 3px;
    top: 45%;
    width: 12px;
    height: 8px;
    /* background-image: url('../../images/icons/dropdown-icn.svg'); */
    background-position: center center;
    background-repeat: no-repeat;
    transition: all 0.3s;
}

.header-section .overlay .navbar .navbar-nav li.nav-dropdown:hover a.nav-dropdown-link::after {
    transform: rotate(180deg);
}

.header-section .overlay .navbar .navbar-nav li.nav-dropdown a img {
    margin-left: 10px;
}

.header-section .overlay .navbar .navbar-nav li.nav-dropdown ul.nav-column {
    position: absolute;
    width: auto;
    min-width: 100%;
    height: auto;
    background-color: #181818;
    top: 100%;
    /* visibility: hidden; */
    /* transition: all 0.3s; */
    z-index: 99;
    clip: rect(1px, 1px, 1px, 1px);
    opacity: 0.1;
    transition: transform 0.1s cubic-bezier(0.2, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.2, 0, 0.2, 1);
    transform: translateY(-10px) scaleY(0.5);
    transform-origin: top;
}

.header-section .overlay .navbar .navbar-nav li.nav-dropdown ul.nav-column li {
    display: block;
    list-style-type: none;
    /* border-bottom: 1px solid var(--border-dark); */
    padding: 10px 5px;
    margin: 0;
}

.header-section .overlay .navbar .navbar-nav li.nav-dropdown ul.nav-column li a {
    font-weight: 400;
    font-size: 14px;
}

.header-section .overlay .navbar .navbar-nav li.nav-dropdown ul.nav-column li::after {
    display: none;
}

.header-section .overlay .navbar .navbar-nav li.nav-dropdown ul.nav-column li:hover {
    background: #303030;
}

.header-section .overlay .navbar .navbar-nav li.nav-dropdown:hover ul.nav-column {
    clip: auto;
    opacity: 1;
    transform: translateY(0) scaleY(1);
}

.header-section .overlay .navbar .btn-sec-navbar .btn {
    margin: 0px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
}

.header-section .overlay .navbar .btn-sec-navbar {
    display: flex;
}

.header-section .overlay .navbar .btn-sec-navbar .f-group {
    width: 150px;
}

.btn-sec-navbar .f-group .f-dropdown>span {
    background: #181818;
    border: 1px solid #737373;
    box-sizing: border-box;
    border-radius: 4px;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    padding: 8px;
}

.header-section .overlay .navbar .btn-sec-navbar .f-group .f-dropdown ul li a img,
.navbar .btn-sec-navbar .f-group .f-dropdown>span img {
    width: 15px;
    margin-right: 5px;
}

.btn-sec-navbar .f-group .f-dropdown.open>span:before,
.btn-sec-navbar .f-group .f-dropdown.open>span:after,
.btn-sec-navbar .f-group .f-dropdown>span:before,
.btn-sec-navbar .f-group .f-dropdown>span:after {
    background: #fff;
}

.header-section .overlay .navbar .btn-sec-navbar .f-group .f-dropdown ul li a {
    color: #fff !important;
}

.header-section .overlay .navbar .btn-sec-navbar .f-group .f-dropdown ul li.active a,
.header-section .overlay .navbar .btn-sec-navbar .f-group .f-dropdown ul li:hover a {
    color: #fff !important;
}

.header-section .overlay .navbar .navbar-toggler i {
    color: #fff;
}

.nav-serch-bar {
    position: relative;
}

.nav-serch-bar .form-control {
    background: #181818;
    border: 1px solid #737373;
    box-sizing: border-box;
    border-radius: 4px;
    color: #737373;
}

.nav-serch-bar .search-dropdown {
    background: #181818;
    border: 1px solid #999999;
    box-sizing: border-box;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 8px 0px;
    width: 350px;
    height: 176px;
    position: absolute;
    top: 45px;
    left: 0;
    z-index: 999;
}

.nav-serch-bar .search-dropdown ul li .search-item img {
    max-width: 40px;
    margin-right: 20px;
}

.nav-serch-bar .search-dropdown ul li .search-item span {
    font-size: 16px;
    color: #fff;
}

.profile-dropdown {
    display: none;
}

.profile-dropdown .pdd-btn {
    background: #181818;
    border: 1px solid #737373;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px;
    cursor: pointer;
}

.profile-dropdown .dropdown-toggle::after {
    display: none;
}

.profile-dropdown .profile-img {
    background: linear-gradient(180deg, #B71F23 0%, #180F52 100%);
    width: 32px;
    height: 32px;
    text-align: center;
    border-radius: 50%;
    margin-right: 10px;
    color: #FFFFFF
}

.profile-dropdown .profile-name {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 14px;
}

.profile-dropdown .profile-dd-list {
    width: 100%;
    height: auto;
    background-color: #181818;
    border: 1px solid #999;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
}

.profile-dropdown .profile-dd-list ul li a {
    color: #fff;
    font-size: 14px;
    margin: 5px 0px;
    width: 100%;
    display: flex;
    align-items: center;
}

.profile-dropdown .profile-dd-list ul li a img {
    width: 18px;
    margin-right: 5px;
}

.profile-dropdown .profile-dd-list ul li:hover {
    background-color: #303030;
}

@media (max-width: 1200px) {
    .nav-serch-bar .form-control {
        max-width: 190px;
    }
}

@media (max-width: 1200px) and (min-width: 992px) {
    .header-section .overlay .navbar .navbar-brand img {
        width: 100px;
    }
    .header-section .overlay .navbar .navbar-nav li a {
        font-size: 14px;
        padding: 0px 0.5rem;
    }
    .header-section .overlay .navbar .btn-sec-navbar .btn {
        font-size: 14px !important;
    }
}

@media (max-width: 1100px) and (min-width: 992px) {
    .header-section .overlay .navbar .navbar-nav li a {
        font-size: 12px;
    }
    .header-section .overlay .navbar .btn-sec-navbar .btn,
    .btn-sec-navbar .f-group .f-dropdown>span {
        font-size: 12px !important;
        padding: 4px;
    }
}

@media (max-width: 1050px) {
    .nav-serch-bar .form-control {
        max-width: 150px;
    }
}

@media (max-width: 1100px) and (min-width: 992px) {
    .header-section .overlay .navbar .btn-sec-navbar .f-group {
        width: 130px;
    }
    .profile-dropdown .profile-name {
        font-size: 12px;
    }
    .profile-dropdown .profile-img {
        width: 25px;
        height: 25px;
    }
}

@media (max-width: 991px) {
    .header-section {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        z-index: 9999;
    }
    .header-section .overlay {
        padding-top: 15px;
        padding-bottom: 15px;
        max-height: 100%;
    }
    .header-section .overlay .navbar .btn-sec-navbar.btn-sec-desktop {
        display: none;
    }
    .header-section .overlay .navbar .navbar-brand img {
        width: 140px;
        margin-bottom: 10px;
    }
    .header-section .overlay .navbar .navbar-nav {
        margin-top: 20px;
        height: 90vh;
    }
    .header-section .overlay .navbar .navbar-collapse {
        padding: 5px 0px;
        border-top: 4px solid #181818;
    }
    .header-section .overlay .navbar .navbar-nav li {
        padding-top: 15px;
        padding-bottom: 15px;
        width: 100%;
        text-align: left;
    }
    .header-section .overlay .navbar .navbar-nav li a {
        font-size: 14px;
    }
    .header-section .overlay .navbar .navbar-nav li::after {
        height: 4px;
    }
    .header-section .overlay .navbar .navbar-nav li.nav-dropdown a.nav-dropdown-link:after {
        right: 15px;
    }
    .header-section .overlay .navbar .navbar-nav li.nav-dropdown ul.nav-column {
        position: static;
        height: 0;
        opacity: 0;
    }
    .header-section .overlay .navbar .navbar-nav li.nav-dropdown:hover ul.nav-column {
        height: auto;
    }
    .header-section .overlay .navbar .btn-sec-navbar .f-group {
        width: 100%;
        max-width: 100%;
    }
    .desktop-search {
        display: none;
    }
    .nav-serch-bar .form-control {
        max-width: 100%;
    }
    .header-section .overlay .navbar .navbar-nav li:hover::after {
        width: 0;
    }
    .nav-serch-bar .search-dropdown {
        width: 99%;
        border: 0;
        height: 80vh;
        background: #000000;
    }
    .profile-dropdown {
        position: absolute !important;
        right: 5%;
        top: 5px;
    }
    .profile-dropdown .pdd-btn {
        background-color: transparent;
        border: none;
    }
    .profile-dropdown .profile-name {
        display: none;
    }
    .profile-dropdown .profile-img {
        box-shadow: 0px 0px 0px 2px #FFFFFF;
    }
    .profile-dropdown .dropdown-menu[data-bs-popper] {
        right: 0 !important;
        left: auto;
    }
}


/*======== TOP NAVIGATION STYLE END========*/