

/*======== MARKETPLACE FILTER PAGE STYLE ========*/

.filter-side .filter-inner .filter-head .filter-heading {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    text-transform: uppercase;
    color: #FFFFFF;
    position: relative;
    display: flex;
    align-items: center;
  }

  .filter-side .filter-inner .filter-head .filter-heading .close-btn {
      position: absolute;
      width: 50px;
      height: 50px;
      line-height: 50px;
      background-color: #737373;
      color: #fff;
      border-radius: 4px;
      right: 0px;
      top: -10px;
      font-size: 30px;
  }

  .filter-side .filter-inner .filter-head .filter-heading .close-btn:hover {
    background-color: var(--primary);
  }

  .filter-side .filter-inner .filter-head .filter-heading span {
    color: var(--primary);
    font-size: 18px;
    cursor: pointer;
    margin-left: 10px;
    text-transform: capitalize;
    font-weight: 400;
    position: relative;
    top: 4px;
  }

  .filter-side .filter-inner .filter-body label {
    display: block;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
  }

  .filter-side .filter-inner .filter-body .col .btn {
    width: 100%;
  }

  .filter-side .filter-inner .filter-body .col .btn.select-btn {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
  }

  .filter-side .filter-inner .filter-body .col input {
    background-color: transparent;
    border: 1px solid #737373;
    color: #fff;
  }

  .filter-side .filter-row .outline-collection {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    width: 100%;
    padding: 12px;
    display: flex;
    align-items: center;
    position: relative;
  }

  .filter-side .filter-row .outline-collection span {
      position: absolute;
      font-size: 12px;
      color: #737373;
      right: 10px;
  }

  .filter-side .filter-inner .filter-body .col .btn.active,
  .filter-side .filter-row .outline-collection.active {
    background-color: var(--primary);
    border-color: var(--primary);
  }

  .filter-side .filter-row .outline-collection.active span {
    color: #c5c1c1;
  }

  @media (max-width: 767px) {
    .marketplace-sec .overlay .sec-main-heaidng {
      text-align: center;
    }

    .marketplace-sec .overlay .sec-main-heaidng .open-filter {
      display: none;
    }

    .marketplace-sec .overlay .sec-main-heaidng .filter-hd-content {
      width: 100%;
    }

    .marketplace-sec .overlay .sec-main-heaidng .filter-hd-content p.text-light {
      color: #999 !important;
      font-size: 14px;
    }

    .marketplace-sec .overlay .sort-col.text-end {
      text-align: center !important;
    }

    .marketplace-sec .overlay .sort-col .n-select {
       width: 100%;
    }

    .collection-explore .overlay .filter-side {
      width: 100%;
    }

    .filter-side .filter-inner .filter-head .filter-heading .close-btn, 
    .filter-side .filter-inner .filter-head .filter-heading .close-btn:hover {
      background-color: transparent;
    }

    .collection-explore .overlay .filter-title h1 {
      font-size: 24px;
      line-height: 32px;
    }
  }


  /*======== MARKETPLACE DETAIL PAGE STYLE ========*/

  .marketplace-detail .card.detail-card {
    background: rgba(24, 24, 24, 0.5);
    border-radius: 12px;
    padding: 20px 20px 20px;
    max-width: 420px;
  }

  .marketplace-detail .card.detail-card img {
    width: 100%;
  }

  .marketplace-detail h1 {
    max-width: 425px;
  }

  .marketplace-detail .detail-header {
    display: flex;
    align-items: flex-start;
    position: relative;
  }

  .marketplace-detail .detail-header .cross-btn {
    width: 48px;
    height: 48px;
    background: rgba(0, 0, 0, 0.6);
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    line-height: 45px;
    color: #fff;
    font-size: 30px;
    position: absolute;
    right: 0;
    top: 10px;
  }

  .marketplace-detail .detail-header .dropdown  {
    right: 8%;
    top: 10px;
    position: absolute;
  }

  .marketplace-detail .detail-header .share-btn {
    width: 48px;
    height: 48px;
    background: rgba(0, 0, 0, 0.6);
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    line-height: 45px;
    color: #fff;
    font-size: 30px;
  }

  .marketplace-detail .detail-header .cross-btn:hover,
  .marketplace-detail .detail-header .share-btn:hover {
    background: #303030;
  }

  
  .marketplace-detail .detail-header .dropdown-toggle::after {
    display: none;
  }

  .marketplace-detail .detail-header .dropdown .dropdown-menu {
    background: #181818;
    border: 1px solid #999999;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }

  .marketplace-detail .detail-header .dropdown .dropdown-menu li a {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
  }

  .marketplace-detail .detail-header .dropdown .dropdown-menu li a:hover {
    background: #303030;
  }

  .marketplace-detail .detail-body .nav-tab, 
  .marketplace-detail .transaction .transaction-header {
    border-bottom: 1px solid #737373;
  }

  .marketplace-detail .detail-body .nav-tab li {
   border: none;
}

  .marketplace-detail .detail-body .nav-tab li button, 
  .marketplace-detail .transaction .transaction-header h2 {
      background-color: transparent;
      color: #fff;
      text-transform: uppercase;
      font-size: 18px;
      border: none;
      position: relative;
      font-weight: 700;
      text-align: left;
      padding-left: 0;
      margin-right: 25px;
  }

  .marketplace-detail .detail-body .nav-tab li button.active {
    background-color: none;
    position: relative;
  }

  .marketplace-detail .detail-body .nav-tab li button.active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: var(--primary);
  }

  .marketplace-detail .detail-body .tab-content {
      font-size: 18px;
      color: #fff;
      background: transparent;
      padding: 15px 0px;
  }

  .marketplace-detail .detail-body .tab-content .title {
    font-weight: 700;
  }

  .marketplace-detail .detail-body .tab-content .name {
    font-weight: 400;
  }

  .marketplace-detail .detail-body .tab-content .name a {
    color: #fff;
    text-decoration: underline;
  }

  .marketplace-detail .price-section .price-detail img {
    width: 48px;
    height: 48px;
  }

  .marketplace-detail .price-section .price-detail .ex-price {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 36px;
  }

  .marketplace-detail .price-section .price-detail .discount {
    color: #999999;
    font-weight: 400;
    font-size: 18px;
  }

  .marketplace-detail .buy-button .btn {
      width: 400px;
      text-transform: uppercase;
  }

  .marketplace-detail .transaction .transaction-header::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 20%;
    height: 4px;
    background-color: var(--primary);
  }

  .marketplace-detail .transaction .transaction-body {
      font-size: 18px;
  }

  .marketplace-detail .transaction .transaction-body .profile-img {
    background: linear-gradient(180deg, #B71F23 0%, #180F52 100%);
    width: 42px;
    height: 42px;
    border-radius: 50%;
  }

  .marketplace-detail .transaction .transaction-body h2 {
    color: #fff;
    font-weight: 700;
    font-size: 18px;
  }

  .marketplace-detail .transaction .transaction-body p {
      color: #777777;
      margin-bottom: 0;
  }

  .marketplace-detail .transaction .transaction-body p.c-num {
    color: var(--primary);
  }

  .marketplace-detail .transaction .transaction-body p.coin-price {
    font-weight: 700;
    color: #fff;
  }

  .marketplace-detail .transaction .transaction-body p.coin-price img {
    margin-right: 5px;
    width: 15px;
  }

  .marketplace-detail .transaction .transaction-body p span.copy-lnk {
    cursor: pointer;
  }

  .Ex-modal .modal-dialog .modal-content {
    background: #0E0404;
    border-radius: 20px;
  }

  .Ex-modal .modal-dialog .modal-content .modal-header {
    padding-left: 13%;
  }

  .Ex-modal h1 {
    font-family: 'Face Off M54';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 0.02em;
    text-transform: lowercase;
    color: #fff;
  }

  .Ex-modal .btn-close {
    background: url(../../images/icons/cross-icon.svg);
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1;
  }

  .Ex-modal .content-sec {
    max-width: 80%;
    margin: auto;
  }

  .Ex-modal .img-item img {
    max-width: 200px;
    height: auto;
    margin: auto;
    margin-bottom: 20px;
  }

  .Ex-modal .copy-link {
    background: #181818;
    border-radius: 4px;
    width: 100%;
    padding: 10px;
    color: #737373;
    font-size: 14px;
    position: relative;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .Ex-modal .copy-link span {
    position: absolute;
    right: 10px;
    cursor: pointer;
  }

  .Ex-modal .modal-dialog .modal-content .table tr:hover td {
    background: #303030;
  }

  .Ex-modal .modal-dialog .modal-content .table tr th {
    color: #999;
    background: #181818;
    font-size: 16px;
    vertical-align: middle;
    font-weight: 400;
    border-bottom: 0px;
  }
   
  .Ex-modal .modal-dialog .modal-content .table tr td {
    color: #fff;
    background: #181818;
    font-size: 16px;
    vertical-align: middle;
    border-bottom: 0px;
  }

  .Ex-modal .modal-dialog .modal-content .table tr td.owner {
    color: var(--primary);
  }

  .Ex-modal .modal-dialog .modal-content .table tr td .btn-light-outline {
    min-width: 140px;
    font-size: 16px;  
    padding: 8px;
    line-height: 20px;
  }

  .table-body {
    overflow-y: auto;
    height: 250px;
  }

  .nft-recp .recp-col.border-bottom {
    border-color: #fff;
  }

  .nft-recp .recp-col {
    font-size: 18px;
    color: #fff;
  }


  .nft-recp .recp-col h1 {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 0;
  }

  .nft-recp .recp-col span.r-amount {
    color: #777777;
    font-size: 16px;
  }

  .nft-recp .recp-col span.total-amount {
    font-size: 24px;
    font-weight: 700;
  }

  .nft-recp .recp-col .form-check label {
    font-size: 14px;
  }

  .nft-recp .recp-col.submit-checkout img {
    max-width: 90px;
    margin: auto;
  }

  .nft-recp .recp-col.submit-checkout .porcessing {
    color: #777777;
    font-size: 14px;
  }

  .card.purchase-completed {
    background: #0E0404;
    border-radius: 20px;
    padding: 60px 0px
  }

  .card.purchase-completed .c-content {
    max-width: 300px;
    margin: auto;
  }

  .card.purchase-completed h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    text-transform: uppercase;
    color: #FFFFFF;
    font-family: 'Open Sans';
    letter-spacing: 0px;
  }

  .card.purchase-completed p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #fff;
  }

  .card.purchase-completed .card-img {
    width: 205px;
    margin: auto;
    margin-bottom: 60px;
  }

  .nft-sale {
    position: relative;
    overflow: unset;
  }

  .profile-area {
    max-width: 300px;
    top: -20%;
  }

  .profile-card {
    background: #222222;
    border-radius: 4px;
    width: 100%;
  }

  .profile-card .card-body  {
    padding: 40px 30px;
  }

  .profile-card .card-body .profile-img {
    width: 110px;
    height: 110px;
    margin: auto;
    border-radius: 50%;
    background: linear-gradient(180deg, #B71F23 0%, #180F52 100%);
    border: 5px solid #fff;
    margin-bottom: 20px;
  }

  .profile-card .card-body .profile-img img {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    object-fit: cover;
  }

  .profile-card .card-body .profile-name {
    font-size: 24px;
    line-height: 33px;
    color: #fff;
  }

  .profile-card .card-body .profile-user-email {
    font-size: 18px;
    line-height: 24px;
    color: #737373
  }

  .profile-card .card-body .copy-link {
    padding: 5px;
    background: #000000;
    border-radius: 4px;
    color: #777;
    font-size: 14px;
  }

  .profile-card .card-body .join-date-time {
    color: #fff;
    font-size: 14px;
  }

  .profile-card .card-body .porfile-social-icon ul li a {
    color: #fff;
    font-size: 20px;
  }

  .profile-card .card-body .porfile-social-icon ul li:hover a {
    color: #777;
  }

  .profile-area .btn.btn-light-outline:hover {
    background-color: var(--primary) !important;
  }

  @media (max-width: 1200px) and (min-width: 992px) {
    .marketplace-detail .detail-header .dropdown {
      right: 10%;
    }
  }

  @media (max-width: 767px) {
    .marketplace-detail .card.detail-card {
      width: 100%;
      max-width: 100%;
    }

    .marketplace-detail .detail-header {
        margin-bottom: 25px;
    }

    .marketplace-detail h1 {
      font-size: 24px;
      line-height: 32px;
      max-width: 240px;
    }

    .marketplace-detail .detail-header .cross-btn {
      display: none;
    }

    .marketplace-detail .detail-header .dropdown {
      right: 0;
    }

    .marketplace-detail .detail-body .nav-tab li button, 
    .marketplace-detail .transaction .transaction-header h2 {
      font-size: 16px;
    }

    .marketplace-detail .detail-body .tab-content, 
    .marketplace-detail .price-section .price-detail .discount, 
    .marketplace-detail .transaction .transaction-body h2 {
      font-size: 16px;
    }

    .marketplace-detail .price-section .price-detail .ex-price {
      font-size: 24px;
    }

    .marketplace-detail .price-section .price-detail img {
        width: 30px;
        height: 30px;
    }

    .marketplace-detail .buy-button .btn {
      width: 100%;
    }

    .marketplace-detail .transaction .transaction-body p {
      font-size: 14px;
    }

    .marketplace-detail .transaction .transaction-body .col-md-4.text-end {
      text-align: left !important;
      padding-left: 12%;
    }

    .Ex-modal h1 {
      font-size: 24px;
      line-height: 32px;
    }

  }