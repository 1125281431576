/* .loading-section {
    width: 100%;
    height: 100%;
    /* background: rgb(17, 17, 17);

background-color: transparent;
top: 0;
z-index: 9999;
position: fixed;

}
*/

.loading-section {
    width: 100%;
    height: 100vh;
    /* background: rgb(17, 17, 17); */
    background-color: transparent;
    /* left: 0; */
    /* top: 0; */
    /* z-index: 9999; */
    /* position: fixed; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../images/bg-1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.loading-spinner {
    width: 8rem;
    height: 8rem;
    /* position: absolute; */
    top: 50%;
    left: 50%;
    padding: 5px;
    transform: translate(-50%, -50%);
}

.loading-spinner::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 8rem;
    height: 8rem;
    border-top: 4px solid var(--primary);
    border-left: 2px solid var(--primary);
    border-right: 3px solid transparent;
    border-radius: 50%;
    -webkit-animation: rotation 0.6s linear infinite;
    animation: rotation 0.6s linear infinite;
    z-index: 9999;
}

.loading-spinner img {
    height: auto;
    width: 5rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}

.load-spinner-sm {
    width: 20px;
    height: 20px;
    position: relative;
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
    border-top: 2px solid var(--primary);
    border-left: 1.5px solid var(--primary);
    border-right: 1px solid transparent;
    border-radius: 50%;
    -webkit-animation: rotation 0.5s linear infinite;
    animation: rotation 0.5s linear infinite;
}

.loading-spinner span.txt-loading {
    position: absolute;
    bottom: -35%;
    left: 30%;
    right: 50%;
    transform: translate(-50%, -50%);
}

@-webkit-keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}